<template>
  <div class="home">
    <h1 class="text-sm-h3 text-h4 my-3 text-center">Add New Order</h1>

    <v-stepper v-model="orderSteps" class="my-4">
      <v-stepper-header>
        <v-stepper-step :complete="orderSteps > 1" step="1">
          Order Details
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="orderSteps > 2" step="2">
          Order Items
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3"> Review </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-text-field
            v-model="orderNumber"
            label="Order Number"
            required
          ></v-text-field>

          <v-select
            v-model="agency"
            :items="agencies"
            label="Agency"
            required
          ></v-select>

          <v-btn color="primary" class="mr-4" @click="orderSteps = 2">
            Next
          </v-btn>
        </v-stepper-content>
        <v-stepper-content step="2">
          <p>Working on {{ orderNumber }} for {{ agency }}</p>
          <v-text-field
            v-model="newModel"
            label="Model"
            required
          ></v-text-field>
          <v-text-field
            v-model="newQty"
            label="Quantity"
            required
          ></v-text-field>

          <v-btn color="secondary" class="mr-4" @click="addItem"> Add </v-btn>
          <v-btn color="secondary" class="ml-4" @click="orderSteps = 3">
            Next
          </v-btn>

          <v-card v-if="items.length">
            <p>There are {{ items.length }} items</p>
            <ul>
              <li v-for="item in items" :key="item.id">
                {{ item.model }} x {{ item.quantity }}
              </li>
            </ul>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-card>
            <v-card-title> MO-{{ orderNumber }} </v-card-title>
            <v-card-subtitle>
              {{ agency }}
            </v-card-subtitle>
            <v-card-text>
              <ul>
                <li v-for="item in items" :key="item.lineItem">
                  {{ item.model }} x {{ item.quantity }}
                </li>
              </ul>
            </v-card-text>
            <v-checkbox v-model="stPO" label="received PO"></v-checkbox>
            <v-checkbox
              v-model="stACK"
              label="Sent OC and drawing"
            ></v-checkbox>
            <v-checkbox v-model="stOC" label="O/C received"></v-checkbox>
            <v-checkbox v-model="stDWG" label="Drawing received"></v-checkbox>
            <v-checkbox v-model="stDP" label="D/P received"></v-checkbox>
            <v-checkbox
              v-model="stReleased"
              label="Released order"
            ></v-checkbox>

            <v-btn color="secondary" class="ml-4" @click="addOrder">
              Save
            </v-btn>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "AddOrder",
  components: {},
  data: () => ({
    orderSteps: 1,
    lineItem: 1,
    newOrder: {},
    orderNumber: "",
    agency: "",
    agencies: [
      "MAT",
      "TSE",
      "ZAF",
      "ZLA",
      "ZUK",
      "ZUS",
      "ZRU",
      "Sena",
      "Summit",
      "direct",
    ],
    items: [],
    newModel: "",
    newQty: "",
    stPO: false,
    stACK: false,
    stOC: false,
    stDWG: false,
    stDP: false,
    stReleased: false,
  }),
  methods: {
    addOrder() {
      const newOrder = {
        id: Math.floor(Date.now() * Math.random()),
        orderNumber: this.orderNumber,
        customer: this.agency,
        items: this.items,
        status: {
          stPO: this.stPO,
          stACK: this.stACK,
          stOC: this.stOC,
          stDWG: this.stDWG,
          stDP: this.stDP,
          stReleased: this.stReleased,
        },
      };
      delete newOrder.id;
      axios
        .post("https://zsh-order-api.herokuapp.com/api/orders", newOrder)
        .then((response) => {
          console.log(response);
          this.$router.push({ name: "Orders" });
        });
      // console.log(JSON.stringify(newOrder));
    },
    addItem() {
      const newItem = {
        lineItem: this.lineItem,
        model: this.newModel,
        quantity: this.newQty,
      };
      this.items.push(newItem);
      this.lineItem++;
      this.newQty = "";
      this.newModel = "";
    },
  },
};
</script>

<style></style>
